<template>
    <div v-loading.fullscreen.lock="loading">
        <the-header/>
        <section class="banner-section">
            <div class="container">
                <div class="banner-caption">
                    <h2>NMRA Certified Online Employee Training</h2>
                    <p>Online employee training that enhances customer satisfaction, reduces risks, and boosts revenue</p>
                </div>
            </div>
        </section>
        <div class="content-area" style="padding-top: 20px">
            <div class="container-fluid">
                <h5 class="mb-4">
                    If you are a current member of the NMRA, you will receive special pricing by selecting the box next to the “Already a member of the NMRA”. Enter your Member ID or name of your restaurant to receive your discount. Next, to get an estimate for one of the packages or courses, enter the required information and click the check box next to the name of the package(s) or course(s) you are interested in. If you have any questions or don’t see a course you need, please contact us at <a :href="'mailto:' + infoEmail" class="linkColor">{{ infoEmail }}</a>.
                </h5>
                <div class="row">
                    <div class="col-lg-7 col-md-12 form-section">
                        <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
                            <div v-if="showCompanyinformation">
                                <form role="form" @submit.prevent="handleSubmit(showAgreement)">
                                    <div class="form-row">
                                        <div class="col-md-6">
                                            <label class="form-control-label">Company Type </label><br />
                                            <el-select class="mr-3" style="width: 100%" placeholder="Select Company Type" rules="required" v-model="company.company_type">
                                                <el-option v-for="(option, index) in company_types" class="select-primary" :value="option.value" :label="option.label" :key="'company_type_' + index"> </el-option>
                                            </el-select>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-control-label">Company Name <span class="req"> *</span></label>
                                            <base-input type="text" name="Company name" placeholder="Company Name" rules="required" v-model="company.name"> </base-input>
                                        </div>
                                        <div class="col-md-6">
                                            <base-input type="text" label="Website" name="Website" placeholder="Website" v-model="company.website"> </base-input>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-control-label">Company Logo</label>
                                            <form>
                                                <file-input v-on:change="onImageChange"></file-input>
                                            </form>
                                        </div>
                                        <div class="col-md-3">
                                            <label class="form-control-label">Address <span class="req"> *</span></label>
                                            <base-input type="text" name="Address" placeholder="Address" rules="required" v-model="company.address_1"> </base-input>
                                        </div>
                                        <div class="col-md-3">
                                            <label class="form-control-label">City <span class="req"> *</span></label>
                                            <base-input type="text" name="City" placeholder="city" rules="required" v-model="company.city"> </base-input>
                                        </div>
                                        <div class="col-md-3">
                                            <label class="form-control-label">State <span class="req"> *</span></label>
                                            <base-input type="text" name="State" placeholder="State" rules="required" v-model="company.state"> </base-input>
                                        </div>
                                        <div class="col-md-3">
                                            <label class="form-control-label">Zip code <span class="req"> *</span></label>
                                            <base-input type="number" name="Zip code" placeholder="Zip" rules="required" v-model="company.zip"> </base-input>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <h3 style="color: #444c57" class="mt-4 ml-2">Administrator Login Information</h3>
                                    </div>
                                    <hr />
                                    <div class="form-row">
                                        <div class="col-md-4">
                                            <label class="form-control-label">First Name <span class="req"> *</span></label>
                                            <base-input type="text" name="First Name" placeholder="First Name" rules="required" v-model="company.first_name"> </base-input>
                                        </div>
                                        <div class="col-md-4">
                                            <label class="form-control-label">Last Name <span class="req"> *</span></label>
                                            <base-input type="text" name="Last Name" placeholder="Last Name" rules="required" v-model="company.last_name"> </base-input>
                                        </div>
                                        <div class="col-md-4">
                                            <label class="form-control-label">Phone <span class="req"> *</span></label>
                                            <base-input name="Phone Number" placeholder="Phone" rules="required" v-model="company.telephone_no" @input="acceptNumber"> </base-input>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-control-label">Email Address <span class="req"> *</span></label>
                                            <base-input type="email" name="Email Address" placeholder="Email Address" rules="required" v-model="company.email"> </base-input>
                                        </div>

                                        <div class="col-md-5">
                                            <label class="form-control-label">Password <span class="req"> *</span></label>
                                            <base-input :type="passwordFieldType" v-if="!(company_id !== '')" name="Password" placeholder="Password" rules="required" v-model="company.password"> </base-input>
                                            <base-input :type="passwordFieldType" v-if="company_id !== ''" name="Password" placeholder="Password" v-model="company.password"> </base-input>
                                        </div>
                                        <div class="col-md-1 password-eye" style="margin-top: 40px">
                                            <span @click.prevent="switchVisibility"><i class="fa fa-eye" title="Show Password"></i></span>
                                        </div>
                                    </div>
                                    <div class="form-row">
                                        <h3>AUTHORIZATION</h3>
                                        <p>
                                            <small> By clicking the "Create Account" button below, you agree that the name you typed in the box above (Your Full Name) will be the electronic representation of your signature for all purposes in relation to the Train 321, LLC legally binding Service Activation Agreement. You agree that your electronic signature is considered an original, for purposes of entering into a contract. </small>
                                        </p>
                                    </div>
                                    <div class="text-center">
                                        <button type="submit" class="btn btn-primary mt-4">Continue</button>
                                    </div>
                                </form>
                            </div>
                            <div class="row" v-else>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label class="form-control-label">First Name <span class="req"> *</span></label>
                                            <base-input type="text" name="First Name" placeholder="First Name" rules="required" v-model="company.first_name"> </base-input>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-control-label">Last Name <span class="req"> *</span></label>
                                            <base-input type="text" name="Last Name" placeholder="Last Name" rules="required" v-model="company.last_name"> </base-input>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-control-label">Phone <span class="req"> *</span></label>
                                            <base-input name="Phone Number" placeholder="Phone" rules="required" v-model="company.telephone_no" @input="acceptNumber"> </base-input>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-control-label">Email Address <span class="req"> *</span></label>
                                            <base-input type="email" name="Email Address" placeholder="Email Address" rules="required" v-model="company.email"> </base-input>
                                        </div>
                                        <div class="col-md-12">
                                            <label class="form-control-label">Company Name <span class="req"> *</span></label>
                                            <base-input type="text" name="Company name" placeholder="Company Name" rules="required" v-model="company.name"> </base-input>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-control-label"># of Locations <span class="req"> *</span></label>
                                            <base-input type="number" min="1" name="Location" rules="required" v-model="company.no_of_locations"> </base-input>
                                        </div>
                                        <div class="col-md-6">
                                            <label class="form-control-label">Estimated # of Users <span class="req"> *</span></label>
                                            <base-input type="number" min="1" name="Users " rules="required" v-model="company.no_of_employees"> </base-input>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12" style="font-style: italic">
                                    <h5 class="reduceFont"><span class="text-danger">*</span>Indicates a required field. The estimated pricing is based upon the number of locations, users and courses selected for your company.</h5>
                                </div>
                            </div>
                        </validation-observer>
                    </div>
                    <div class="col-lg-5 col-md-12">
                        <div class="row">
                            <div class="col-md-12">
                                <base-checkbox style="padding-left: 5px" v-model="already_member"><b>Already a member of the NMRA? (Enter your Member ID to receive a discount).</b></base-checkbox>
                            </div>
                            <div class="col-md-11 mt-1" style="margin-left: 5%">
                                <h5>
                                    Not a member,
                                    <a class="linkColor" target="__blank" href="https://www.nmrestaurants.org/nmra-membership/"> click here </a>
                                    to join.
                                </h5>
                            </div>

                            <div class="col-md-12 mt-1" v-if="already_member">
                                <label class="form-control-label">Member ID:</label>
                                <input type="text" :style="inputStyle" id="memberId" class="form-control" v-model="member_id" placeholder="Provide member ID" />
                            </div>
                            <div class="col-md-12 mt-2">
                                <el-checkbox-group v-model="checked_coursefolders">
                                    <span v-for="option in coursefolders" :key="option.id">
                                        <el-popover :id="option.id" :key="option.id" :ref="`fromPopOver${option.id}`" placement="top-start" trigger="hover" width="500">
                                            <div class="provideScroller">
                                                <ul>
                                                    <li v-for="(courses, index) in option.attachedCourses" :key="index">
                                                        {{ courses.name }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </el-popover>
                                        <el-checkbox :label="option.id" @change="checkeAllCourseFolders">{{ option.coursefolder_name }}</el-checkbox>
                                        <span v-popover="`fromPopOver${option.id}`" class="text-primary knowMore-info">Learn More</span>
                                    </span>
                                </el-checkbox-group>
                                <el-checkbox-group v-model="checked_courses">
                                    <span v-for="option in course" :key="option.id">
                                        <el-checkbox :label="option.id" @change="checkeAllCourses">{{ option.course_name }}</el-checkbox>
                                        <br />
                                    </span>
                                </el-checkbox-group>
                            </div>
                        </div>

                        <!-- Promocode Section -->
                        <div class="row align-items-end mt-2">
                            <div class="col-md-6">
                                <div class="form-group promo_inputBOX mb-0">
                                    <label>Promo Code
                                    </label>
                                    <input type="text" class="form-control" id="promo-code" placeholder="Enter your Promo Code"  v-model="promocode">
                                </div>
                            </div>
                            <div class="col-6 mt-md-0 mt-3">
                                <base-button class="btn-success" @click.prevent="applyPromoCode">Apply Coupon</base-button>
                            </div>
                        </div>

                        <!--End Promocode Section -->

                        <div class="" v-if="!showCompanyinformation && (checked_courses.length || checked_coursefolders.length) && !lead_id">
                            <base-button class="mt-2" @click.prevent="checkIfNMRAMember">Click Here for Estimate </base-button>
                        </div>
                        <div class="" v-else>
                            <base-button v-if="!showPricePlan" class="basebutton mt-2" disabled>Click Here for Estimate </base-button>
                        </div>
                        <div v-if="showPricePlan && lead_id" class="price-area">
                            <div class="row">
                                <div class="col-md-12" v-if="!onlySexualCourse" style="color: darkblue">
                                    <div class="row">

                                        <div class="col-md-9 col-8">
                                            <small>
                                                Cost Per Month:
                                            </small>
                                            <hr style="margin-top: -9px !important; margin-bottom: 4px !important; margin-left: 250px" />
                                        </div>
                                        <div class="col-md-3 col-4">
                                            <small>{{ formatPrice(total_cost) }}</small>
                                        </div>

                                        <div class="col-md-9 col-8">
                                            <small>
                                                Price if paid in full for the year (10% off)
                                            </small>
                                            <hr style="margin-top: -9px !important; margin-bottom: 4px !important; margin-left: 250px" />
                                        </div>
                                        <div class="col-md-3 col-4">
                                            <small>{{ formatPrice(perYearCost) }}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                        </div>
                        <div>
                            <base-button v-if="showCompanyinformation || lead_id" type="danger" @click.prevent="submitLead">Re-estimate </base-button>
                            <base-button style="margin-left: 20px" v-if="!showCompanyinformation && lead_id" type="success" @click.prevent="companyDetails()">Continue Signup </base-button>
                        </div>
                        <div class="mt-3">
                            <span v-if="!onlySexualCourse">
                                <h5>
                                    <span class="req">*</span>
                                    Monthly prices are based upon a 1-year agreement that will automatically renew each year.
                                </h5>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal :show.sync="agreementModal" class="user-modal">
            <h3 slot="header">Service Activation Agreement</h3>
            <form>
                <div class="agreement-content">
                    <agreement type="company"></agreement>
                </div>
                <div class="text-center mt-2">
                    <base-button type="success" @click.prevent="finalCreateAccount"> I Agree </base-button>
                    <base-button type="danger" @click.prevent="cancelAgreement"> Cancel </base-button>
                </div>
            </form>
        </modal>
        <modal :show.sync="showPaymentOption">
            <h4 slot="header" style="color: #444c57" class="title title-up text-center">Payment</h4>
            <credit-card v-if="showPaymentOption" type="company" :monthlyAmount="total_cost" :yearlyAmount="perYearCost" :onlySexualCourse="onlySexualCourse" :city="company.city" :state="company.state" :address="company.address_1" :zip="company.zip" :enablePaymentButton="enablePaymentButton" v-on:payClicked="payClicked" />
        </modal>

        <modal :show.sync="paymentModalMessage">
          <h1 class="text-capitalize text-center text-strong m-0 payment-modal-text text-danger font-weight-500">Your payment is processing...</h1>
        </modal>
        
        <the-footer/>
    </div>
</template>

<script>
import FileInput from "@/components/Inputs/FileInput";
import { Checkbox, CheckboxGroup, Option, OptionGroup, Select, Table, TableColumn } from "element-ui";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import CreditCard from "@/nmra/partials/CreditCard";
import Agreement from "@/views/Pages/Agreement.vue";
import TheFooter from "@/nmra/partials/Footer.vue";
import TheHeader from "@/nmra/partials/Header.vue";

export default {
    name: "register",
    components: {
        Agreement,
        FileInput,
        CreditCard,
        [Select.name]: Select,
        [Option.name]: Option,
        [OptionGroup.name]: OptionGroup,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Checkbox.name]: Checkbox,
        [CheckboxGroup.name]: CheckboxGroup,
        TheHeader,
        TheFooter,
    },
    data() {
        return {
            loading: false,
            paymentModalMessage: false,
            users: 0,
            already_member: false,
            member_id: "",
            courseSelectionFocused: false,
            courseFolderSelectionFocused: false,
            focused: "blue-theme",
            formData: {
                company_name: "",
                first_name: "",
                last_name: "",
                company_location_num: "",
                company_employee_num: "",
                company_address_1: "",
                company_address_2: "",
                company_phone: "",
                company_email: "",
                company_zip: "",
                website: "",
                company_type: "",
                username: "",
                parent_id: "",
                image: "",
                company_city: "",
                company_state: "",
                company_password: "",
            },
            baseUrl: this.$baseUrl,
            hot_user: "",
            hot_token: "",
            config: "",
            company_id: "",
            creatAccountClicked: false,
            company_types: [],
            image: "",
            sub_total: "",
            special_courses: [],
            company: {
                first_name: "",
                last_name: "",
                company_type: "",
                parent_company: "",
                name: "",
                administrator: "",
                no_of_locations: "",
                no_of_employees: "",
                address_1: "",
                address_2: "",
                city: "",
                state: "",
                zip: "",
                logo: "",
                telephone_no: "",
                email: "",
                password: "",
            },

            search: "",
            contactUsModal: false,
            agreementModal: false,
            passwordFieldType: "password",
            courses: [],
            coursefolders: [],
            basicPackage1: [],
            basicPackage2: [],
            services: [],
            contact: {
                name: "",
                phone: "",
                email: "",
                message: "",
            },
            siteName: "",
            infoEmail: "nmra@train321.com",
            lead_id: "",
            checked_courses: [],
            checked_other_courses: [],
            checked_coursefolders: [],
            companyEstimateDetailModel: false,
            showCompanyinformation: false,
            showPricePlan: false,
            total_cost: "",
            total_discount: "",
            perYearCost: "",
            employees_count: "",
            locations_count: "",
            course: "",
            role: "",
            showPaymentOption: false,
            onlySexualCourse: 1,
            inputStyle: "",
            enablePaymentButton: false,
            showCourseModal: false,
            checkedModalCourse: {
                courseid: 0,
                numberOfUsers: 0,
                numberOfManagers: 0,
            },
            selectedCourses: [],
            additionalCourses: [],
            promocode: "",
            swapCourses: false,
        };
    },
    mounted() {
        if (this.$route.query.inner) {
            this.$gtag.event("S.H.P. Company signup", { method: "Google" });
        } else if (this.$route.query.course_type) {
            this.swapCourses = true;
            this.$gtag.event("Sexual Harassment Prevention Company signup", {
                method: "Google",
            });
        } else {
            this.$gtag.event("Company signup", { method: "Google" });
        }
    },
    async created() {
        localStorage.removeItem("fname");
        localStorage.removeItem("lname");
        localStorage.removeItem("email");
        localStorage.removeItem("courses");
        if (this.$route.query.role) {
            this.role = this.$route.query.role;
        }

        this.$http.post("company/company_dropdown_data", {}).then((resp) => {
            for (let type of resp.data.companytype) {
                let obj = {
                    label: type.type,
                    value: type.id,
                };
                this.company_types.push(obj);
            }
        });
        this.$http.get("nmra/discountRules").then(response => {
            for (let course of response.data.courses) {
                let obj = {
                    checked: false,
                    id: course.id,
                    course_name: course.name,
                    member_price: course.member,
                    non_member_price: course.non_member,
                    course_type: course.course_type,
                    cost: course.cost
                };
                this.courses.push(obj);
            }
            this.course = this.courses;
            for (let coursefolder of response.data.course_folders) {
                let obj1 = {
                    id: coursefolder.id,
                    coursefolder_name: coursefolder.folder_name,
                    member_price: coursefolder.member,
                    non_member_price: coursefolder.non_member,
                    attachedCourses: coursefolder.active_courses
                };

                this.coursefolders.push(obj1);
            }
        });
    },
    methods: {
        checkeAllCourseFolders: function (isChecked) {
            this.checked_coursefolders = [];
            this.checked_courses = [];
            if(isChecked) {
                this.coursefolders.forEach(courseF => {
                    this.checked_coursefolders.push(courseF.id);
                });
                this.course.forEach(course => {
                    this.checked_courses.push(course.id);
                })
            }
        },
        checkeAllCourses: function (isChecked) {
            if(isChecked) {
                this.course.forEach(course => {
                    this.checked_courses.push(course.id);
                })
            } else {
                this.checked_courses = [];
                this.checked_coursefolders = [];
            }
        },
        showDone() {
            this.courseSelectionFocused = true;
        },
        doneClicked(e) {
            this.courseSelectionFocused = false;
        },
        showDoneButton() {
            this.courseFolderSelectionFocused = true;
        },
        doneButtonClicked(e) {
            this.courseFolderSelectionFocused = false;
        },
        cancelAgreement() {
            this.agreementModal = false;
        },
        showContactUs() {
            this.contactUsModal = true;
        },
        finalCreateAccount() {
            //this.createAccount(this.formData);
            this.agreementModal = false;
            this.showPaymentOption = true;
        },
        payClicked(cardData) {
            this.loading = true;
            this.paymentModalMessage = true;
            let payment = {
                payment_type: "",
                cardholder_street_address: cardData.address + "," + cardData.city + "," + cardData.state,
                cardholder_zip: cardData.zip,
                transaction_amount: "",
                token: cardData.token,
            };
            if (this.onlySexualCourse) {
                payment.payment_type = "one-time";
            } else {
                payment.payment_type = cardData.paymentType;
            }
            if (cardData.paymentType == "monthly") {
                payment.transaction_amount = this.total_cost.toFixed(2);
            }
            if (cardData.paymentType == "yearly") {
                payment.transaction_amount = this.perYearCost.toFixed(2);
            }
            this.formData.payment = payment;
            this.formData.address_1 = cardData.address;
            this.formData.company_address_1 = cardData.address;
            this.formData.company_state = cardData.state;
            this.formData.company_city = cardData.city;
            this.formData.company_zip = cardData.zip;
            this.loading = false;
            this.createAccount(this.formData);
        },
        showAgreement() {
            this.formData = {
                company_name: this.company.name,
                first_name: this.company.first_name,
                last_name: this.company.last_name,
                company_location_num: this.company.no_of_locations,
                company_employee_num: this.company.no_of_employees,
                company_address_1: this.company.address_1,
                company_address_2: this.company.address_2,
                company_phone: this.company.telephone_no,
                company_email: this.company.email,
                company_zip: this.company.zip,
                website: this.company.website,
                company_type: this.company.company_type,
                username: this.company.email,
                parent_id: this.company.parent_company,
                image: this.image,
                company_city: this.company.city,
                company_state: this.company.state,
                company_password: this.company.password,
                course_ids: this.checked_courses,
                courseFolders: this.checked_coursefolders,
                otherCourseFolders: this.checked_other_courses,
                already_member: this.already_member,
                member_id: this.member_id,
                status: 1,
                payment: [],
                card_info: [],
                i_agree: true,
            };
            this.agreementModal = true;
        },

        applyPromoCode: function () {
            this.submitLead();
            this.loading = false;
        },

        formatPrice(value) {
            return "$ " + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
        },
        checkIfNMRAMember() {
            if (!this.already_member) {
                Swal.fire({
                    title: "Already a NMRA Member?",
                    html: '<a target="__blank" href="https://www.nmrestaurants.org/nmra-membership/"> Click here</a> to join.',
                    showCancelButton: true,
                    confirmButtonClass: "btn btn-success btn-fill",
                    cancelButtonClass: "btn btn-danger btn-fill",
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    buttonsStyling: false,
                    icon: "question",
                }).then((result) => {
                    if (result.value) {
                        this.inputStyle = "border:2px solid #f8981c;";
                        this.already_member = true;
                    } else {
                        this.submitLead();
                    }
                });
            } else {
                this.submitLead();
            }
        },
        submitLead() {
            if (this.company.no_of_employees !== "" && this.company.no_of_employees > 201) {
                return Swal.fire({
                    title: "Attention!",
                    html: "Please contact our sales team at <a href='mailto:NMRA@train321.com'>NMRA@train321.com</a> for special pricing.",
                    icon: "warning",
                    confirmButtonClass: "btn btn-success btn-fill",
                    confirmButtonText: "OK",
                });
            }
            this.loading = true;
            let data = {
                company_name: this.company.name,
                first_name: this.company.first_name,
                last_name: this.company.last_name,
                number_of_locations: this.company.no_of_locations,
                number_of_employees: this.company.no_of_employees,
                email: this.company.email,
                phone_num: this.company.telephone_no,
                user_type: "corporate",
                already_member: this.already_member,
                member_id: this.member_id,
                course_ids: this.checked_courses,
                course_folders: this.checked_coursefolders,
                other_courses: this.checked_other_courses,
                promocode: this.promocode,
            };
            this.$http
                .post("nmra/lead", data)
                .then((resp) => {
                    this.lead_id = resp.data.user_id;
                    this.companyEstimateDetailModel = false;
                    this.total_cost = resp.data.sub_total;
                    this.sub_total = resp.data.sub_total;
                    this.employees_count = resp.data.number_of_employees;
                    this.locations_count = resp.data.number_of_locations;
                    this.showPricePlan = true;
                    this.perYearCost = resp.data.perYearCost;
                    this.yearDiscount = resp.data.yearDiscount;
                    this.discount = resp.data.discount;
                    this.onlySexualCourse = resp.data.only_sexual_course;
                    this.additionalCourses = resp.data.additionalCourses;
                    this.promocode = this.promocode;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    this.paymentModalMessage = false;
                    if (error.response.status === 422) {
                        return Swal.fire({
                            title: "Error!",
                            html: error.response.data.message,
                            icon: "error",
                        });
                    }
                });
        },
        companyDetails() {
            this.showCompanyinformation = true;
        },
        companyEstimateDetail() {
            this.companyEstimateDetailModel = true;
        },
        hideModel() {
            this.companyEstimateDetailModel = false;
        },
        switchVisibility() {
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
        },
        cancelContact() {
            this.contactUsModal = false;
        },
        saveContact() {
            this.loading = true;
            let data = {
                name: this.contact.name,
                email: this.contact.email,
                phone: this.contact.phone,
                message: this.contact.message,
            };
            this.$http
                .post("nmra/contact", data)
                .then((resp) => {
                    this.contactUsModal = false;
                    this.loading = false;
                    Swal.fire({
                        title: "Success!",
                        text: resp.data.message,
                        icon: "success",
                    });
                })
                .catch(function (error) {
                    this.loading = false;
                    if (error.response.status === 422) {
                        let respmessage = error.response.data.message.replace(/,/g, "\n");
                        Swal.fire({
                            title: "Error!",
                            text: respmessage,
                            icon: "error",
                        });
                    }
                });
        },

        acceptNumber() {
            var x = this.company.telephone_no.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.company.telephone_no = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");

            var y = this.contact.phone.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.contact.phone = !y[2] ? y[1] : "(" + y[1] + ") " + y[2] + (y[3] ? "-" + y[3] : "");
        },
        onImageChange(e) {
            let files = e;
            if (!files.length) return;
            this.createImage(files[0]);
        },
        createImage(file) {
            let reader = new FileReader();
            let vm = this;
            reader.onload = (e) => {
                vm.image = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        createAccount(formDataSubmitted) {
            delete this.$http.defaults.headers["authorization"];
            this.creatAccountClicked = true;
            this.loading = true;
            this.$http
                .post("nmra/companyRegister", formDataSubmitted)
                .then((resp) => {
                    let ids = [];
                    let obj = {
                        id: resp.data.id,
                    };
                    ids.push(obj);
                    this.$http
                        .post("company/welcome_email", {
                            form_data: formDataSubmitted,
                            password: this.company.password,
                            ids: ids,
                        })
                        .then((resp) => {
                            this.agreementModal = this.showPaymentOption = this.showusermodel = this.loading = this.enablePaymentButton = false;
                            Swal.fire({
                                title: "Success!",
                                text: `Account created successfully.`,
                                icon: "success",
                            });
                            this.$router.push("/nmra-login");
                        })
                        .catch((error) => {
                            this.agreementModal = this.showPaymentOption = this.showusermodel = this.loading = this.enablePaymentButton = false;
                            this.$router.push("/nmra-login");
                            return Swal.fire({
                                title: "Success!",
                                text: "Account created successfully.",
                                icon: "success",
                            });
                        });
                })
                .catch((error) => {
                    this.agreementModal = this.showPaymentOption = this.showusermodel = this.loading = this.enablePaymentButton = false;
                    return Swal.fire({
                        title: "Error!",
                        text: error.response.data.message,
                        icon: "error",
                    });
                });
        },
    },
};
</script>
<style scoped>

.payment-modal-text {
    animation: textBlink 2s linear infinite;
}
@keyframes textBlink{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}
.banner-caption h2 {
    font-size: 30px;
}

.form-section {
    background-color: transparent;
    padding: 40px;
    border-right: 1px solid #999999;
}

.course-section {
    padding: 40px;
    background-color: #ffffff !important;
}

.py-5 {
    padding-bottom: 0px !important;
}

.mt--6 {
    margin-top: -6rem !important;
}

.mt--12 {
    margin-top: -12rem !important;
}

.search-wrapper {
    position: relative;
}

.courseList {
    max-height: 250px !important;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
}

hr {
    margin-top: 2px !important;
    margin-bottom: 20px !important;
}

.basebutton.disabled:hover {
    cursor: not-allowed;
}

@media (min-width: 992px) {
    .pt-lg-9,
    .py-lg-9 {
        padding-top: 3rem !important;
    }

    .password-eye span {
        border: 1px solid #808080b3;
        padding: 8px;
        border-radius: 5px;
        background: #80808029;
    }
}

@media (min-width: 768px) {
    .col-md-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 25.33333%;
    }
}

/* ============17/11/2020============ */

#selected_course li {
    font-size: 0.89em;
}

#serviceAgreement {
    float: left;
    height: 300px;
    overflow: auto;
}

#serviceAgreement p {
    font-size: 0.81rem;
    text-align: justify;
}

.el-select-group__title {
    text-decoration: underline !important;
    font-weight: bolder !important;
}

.el-select-dropdown__item {
    font-size: 13px !important;
}

.reduceFont {
    font-weight: 400 !important;
}

.price-area .row {
    margin-top: 5px;
}

.bg-gradient-primary {
    background: linear-gradient(87deg, #07c9fb 0, #ffffff 100%) !important;
}

.req {
    color: red;
}

.knowMore-info {
    font-size: 13px;
    margin-left: 6px;
    text-decoration: underline;
    font-style: italic;
    color: #b73e23 !important;
    cursor: pointer;
}

.provideScroller {
    padding-top: 10px;
    overflow-y: auto !important;
    max-height: 250px !important;
}

.login-inputs {
    padding-top: 20px;
}

.ctm-btn {
    background-color: #b73e23;
    color: #ffffff;
    border-radius: 0 !important;
    font-size: 17px;
    margin-right: 5px;
}

.login {
    margin-right: 0px !important;
}

.el-checkbox-group > span {
    display: block;
}
body:has(.form-section) .content-area{
    min-height: 66vh !important;
}

@media only screen and (max-width: 991px) and (orientation: portrait){
    span.el-checkbox__input {
    display: inline-block;
    height: 20px;
    width: 20px;
}
.knowMore-info[data-v-27320a00] {
    white-space: nowrap;
 }
}
@media only screen and (max-width: 768px) {
    span.el-checkbox__input {
    display: inline-block;
    height: 20px;
    width: 20px;
}
.knowMore-info[data-v-27320a00] {
    white-space: nowrap;
    margin-left: 22px;
 }
}
</style>
